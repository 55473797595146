export { onBeforeRoute };

import type { PageContext, Url } from "vike/types";
import {
  getCanonicalPath,
  getExplicitLocale,
  getLocaleFromContext,
  locales,
} from "../lib/locales.js";
import { useExplicitLocale, useLocale } from "../lib/pathnames.jsx";

function onBeforeRoute(pageContext: PageContext) {
  const urlLogical = getCanonicalPath(pageContext.urlParsed.pathname);
  const locale =
    getExplicitLocale(pageContext.urlParsed.pathname) || locales[0];
  return {
    pageContext: {
      // Make `locale` available as `pageContext.locale`
      locale,
      // Vike's router will use pageContext.urlLogical instead of pageContext.urlOriginal
      urlLogical: urlLogical,
    },
  };
}
