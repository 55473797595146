const routeRegex = /\/([^\/]+)(?:\/([^\/]+))?/;

export function route(pageContext) {
  const match = pageContext.urlPathname.match(routeRegex);
  if (!match) return false;
  const [, id, product] = match;

  if (product) {
    return {
      routeParams: { id, product },
    };
  } else {
    return {
      routeParams: { id },
    };
  }
}
